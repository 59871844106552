import Admin from './views/Admin/Admin';
import ErrorPage from './views/ErrorPage/ErrorPage';
import Homepage from './views/Homepage/Homepage';
import InitializeApp from './components/InitializeApp/InitializeApp';
import Settings from './views/Settings/Settings';
import RequireAuth from './components/RequireAuth/RequireAuth';
import { Routes, Route } from 'react-router-dom';
import GenericPage from './components/GenericPage/GenericPage';

function App() {
  return (
    <Routes>
      <Route path="*" element={<Homepage />} />
    </Routes>
  );
}

export default App;