import { Fragment, useState } from 'react';
import Header from '../../components/Header/Header';
import { Container } from '@mui/material';
import Alert from '../../components/Alert/Alert';

import { useSelector } from 'react-redux';

const Homepage = () => {
  const userInfo = useSelector((state) => state.auth.userInfo);

  return (
    <div className="Homepage">
      <Header loading />
      <main>
        {/* // General content displayed regardless of auth status  */}
        <div>
          <Container maxWidth="lg" sx={{ marginTop: '36px' }}>
            <Alert data={{
              severity: "info",
              title: "Pardon the Dust",
              message: "系统维护中，稍后见！ We are working hard for your better experience. Check back soon! 期间如需校验证书真伪，请通过 support@mail.mininfinity.org 联系我们。 Contact support@mail.mininfinity.org to verify credentials at this time."
            }} />
          </Container>
        </div>

        {/* // Logic to displace different content depending on if user is authed
        {userInfo?.roles.includes(100001) ? (
          // If authed
          <></>
        ) : (
          // If not
          <></>
        )} */}
      </main>
    </div>
  );
}

export default Homepage;
